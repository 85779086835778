
:global( html ){
  height: 100%;
  width: 100%;
  overflow: hidden;
}

:global( body ) {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  padding: 20px;
  font-family: "Homemade Apple";
}

.Main-Link{
  text-decoration: none;
  color: black;
}

.App-title {
  font-size: 3.5em;
  margin-bottom: 0px;
  margin-top: 0;
}


@media (max-width: 525px) {
  .App-title {
    font-size: 2em;
  }
}


.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

:global(#root) {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}