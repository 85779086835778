.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1270px;
  padding: 0 5px;
  box-sizing: border-box;
  margin: auto;
  padding-bottom: 50px;
  justify-content: space-around;
}


.imageLink {
  width: calc(25% - 20px);
  margin: 10px;
}

.thumbnail {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
}


@media (min-width: 1259px){
  .container:after {
    content: "";
    flex: auto;
  } 
}

@media (max-width: 1259px){
  .container{
    max-width:1070px;
    padding: 0 5px; 
  }
  .container:after {
    content: "";
    flex: auto;
  }

  .imageLink {
    width: calc(33% - 20px);
  }  
}

@media (max-width: 800px){
  .container {
    justify-content: center; 
  }
  
  .imageLink {
    width: calc(50% - 4px);
    margin: 2px 0;
  }
  
  .imageLink:nth-child(odd) {
    margin-right: 4px;
  }

  .imageLink:nth-child(even) {
    margin-left: 4px;
  }
}

.filler {
  display: block;
}