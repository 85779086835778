.tabContainer{
  margin-bottom: 20px;
}
.tab {
  margin: 10px 10px;
  color: black;
  text-decoration: none;
}
.current.tab{
  font-weight: 700;
}

.menuButton {
  display: none;
}

@media screen and (max-width: 550px) {
  .menuButton {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
  }

  .menuButton.open {
    border-bottom: 1px solid rgba(0,0,0,.3);
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .tabContainer {
    display: flex;
    flex-direction: column;
  }

  .tabContainer.hidden {
    display: none;
  }

}