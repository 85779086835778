export default [
{
name: 'New folder',
contents: [
{
name: 'A bear',
thumbnail: require('./New folder/A bear/thumbnail.jpg'),
images: [
require('./New folder/A bear/Big.jpg')
]
},
{
name: 'B. Whale nursery',
thumbnail: require('./New folder/B. Whale nursery/thumbnail.jpg'),
images: [
require('./New folder/B. Whale nursery/Big.jpg')
]
},
{
name: 'C old ppl',
thumbnail: require('./New folder/C old ppl/thumbnail.jpg'),
images: [
require('./New folder/C old ppl/Big.jpg')
]
},
{
name: 'D. Dancing girl room',
thumbnail: require('./New folder/D. Dancing girl room/thumbnail.jpg'),
images: [
require('./New folder/D. Dancing girl room/Big.jpg')
]
},
{
name: 'E. Nancy dew',
thumbnail: require('./New folder/E. Nancy dew/thumbnail.jpg'),
images: [
require('./New folder/E. Nancy dew/Big.jpg')
]
},
{
name: 'F Mom',
thumbnail: require('./New folder/F Mom/thumbnail.jpg'),
images: [
require('./New folder/F Mom/Big.jpg')
]
},
{
name: 'G class',
thumbnail: require('./New folder/G class/thumbnail.jpg'),
images: [
require('./New folder/G class/Big.jpg')
]
},
{
name: 'H white fox',
thumbnail: require('./New folder/H white fox/thumbnail.jpg'),
images: [
require('./New folder/H white fox/Big.jpg')
]
},
{
name: 'I bedroom tent',
thumbnail: require('./New folder/I bedroom tent/thumbnail.jpg'),
images: [
require('./New folder/I bedroom tent/Big.jpg')
]
},
{
name: 'J carousel',
thumbnail: require('./New folder/J carousel/thumbnail.jpg'),
images: [
require('./New folder/J carousel/Big.jpg')
]
},
{
name: 'K Picnic',
thumbnail: require('./New folder/K Picnic/thumbnail.jpg'),
images: [
require('./New folder/K Picnic/Big.jpg')
]
},
{
name: 'L Star dream',
thumbnail: require('./New folder/L Star dream/thumbnail.jpg'),
images: [
require('./New folder/L Star dream/Big.jpg')
]
},
{
name: 'M Denmark',
thumbnail: require('./New folder/M Denmark/thumbnail.jpg'),
images: [
require('./New folder/M Denmark/Big.jpg')
]
},
{
name: 'N Tent reading',
thumbnail: require('./New folder/N Tent reading/thumbnail.jpg'),
images: [
require('./New folder/N Tent reading/Big.jpg')
]
},
{
name: 'O park',
thumbnail: require('./New folder/O park/thumbnail.jpg'),
images: [
require('./New folder/O park/Big.jpg')
]
},
{
name: 'P dream room window',
thumbnail: require('./New folder/P dream room window/thumbnail.jpg'),
images: [
require('./New folder/P dream room window/Big.jpg')
]
},
{
name: 'Q forest',
thumbnail: require('./New folder/Q forest/thumbnail.jpg'),
images: [
require('./New folder/Q forest/Big.jpg')
]
},
{
name: 'R spot mom',
thumbnail: require('./New folder/R spot mom/thumbnail.jpg'),
images: [
require('./New folder/R spot mom/Big.jpg')
]
},
{
name: 'S poodle',
thumbnail: require('./New folder/S poodle/thumbnail.jpg'),
images: [
require('./New folder/S poodle/Big.jpg')
]
},
{
name: 'T Imagination',
thumbnail: require('./New folder/T Imagination/thumbnail.jpg'),
images: [
require('./New folder/T Imagination/Big.jpg')
]
},
{
name: 'U boy and dog',
thumbnail: require('./New folder/U boy and dog/thumbnail.jpg'),
images: [
require('./New folder/U boy and dog/Big.jpg')
]
},
]},

];